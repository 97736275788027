/*
 * Global Settings for API
 */

export const mainUrl = process.env.REACT_APP_API_URL
export const meetApiUrl = process.env.REACT_APP_API_MEET_URL
export const redirectEndpoints = process.env.REACT_APP_CHAT_URL
export const testDeviceUrl = `${redirectEndpoints}?test=true`
export const medicalRequestsApiUrl = process.env.REACT_APP_MEDICAL_REQUESTS_API_URL

// Endpoints for REST APIs
export const apiEndpoints = {
  ACCOUNTS: '/v0/accounts/',
  AGENDA_RESTRICTIONS: (professionalUsername, slotId) => `/v0/agenda/${professionalUsername}/slots/${slotId}/restrictions/`,
  AGENDA_AVAILABILITY: slug => `/v0/persons/${slug}/agenda/availabilities/`,
  AGENDA_SLOTS: (slug, group_by) =>
    `/v0/persons/${slug}/agenda/slots/${group_by ? `?group_by=${group_by}` : ''}`,
  AGENDA_SLOT: (slug, id) => `/v0/persons/${slug}/agenda/slots/${id ? `${id}/` : ''}`,
  ALL_AVAILABILITY: '/v0/agenda/',
  ALL_ORDER_LIST: '/v0/orders/',
  AUTH: '/v0/authentication/',
  BLOCKS: '/v0/blocks/',
  CHECKOUT: orderId => `/v0/orders/${orderId}/checkout/`,
  CREDIT_CARDS: (slug, profile) => `/v0/persons/${slug}/profiles/${profile}/credit_cards/`,
  DOCUMENTS: '/v0/documents/',
  GROUP_PLANS: plan_group_code => `/v0/plans-groups/${plan_group_code}/`,
  GROUP_PLAN_ELIGIBLES: plan_group_code => `/v0/plan-groups/${plan_group_code}/eligibles/`,
  GROUP_PLAN_ELIGIBLES_FILE: plan_group_code =>
    `/v0/plan-groups/${plan_group_code}/eligibles/csv/`,
  LOGOUT: '/v0/logout/',
  ME: '/v0/me/',
  ME_REVIEWS: '/v0/me/reviews/',
  ME_REVIEW: reviewId => `/v0/me/reviews/${reviewId}/`,
  MAX_PRICE: product_code => `/v0/profiles/products/${product_code}/maxprice/`,
  MIN_PRICE: product_code => `/v0/profiles/products/${product_code}/minprice/`,
  ORDERS: id => `/v0/orders/${id ? `${id}/` : ''}`,
  ORDERS_ITEM: (orderId, itemId) => `/v0/orders/${orderId}/items/${itemId}/`,
  PARTNER_ROLES: '/v0/profiles/',
  PATIENTS: '/v0/patients/',
  PATIENT_PERSONAL_DATA: username => `/v0/patients/${username}/`,
  ACCOUNT_CHANGE_EMAIL: email => `/v0/accounts/${email}/`,
  PATIENTS_PARTICULAR: '/v0/patients/particular/',
  PATIENTS_PLAN: plan => `/v0/patients/${plan}/`,
  PATIENTS_PLAN_EDIT: username => `/v0/patients/${username}/plans/`,
  PATIENTS_BY_EMAIL: email => `/v0/patients/${email}/email/`,
  PATIENTS_BY_CPF: cpf => `/v0/patients/${cpf}/cpf/`,
  PATIENTS_MEDICAL_REQUEST: '/v0/patients/medical-request/',
  PATIENTS_PLANS: (username, planCode) => 
    planCode ?
      `/v0/patients/${username}/plans/${planCode}/` :
      `/v0/patients/${username}/plans/`
  ,
  PROFESSIONALS: '/v0/professionals/',
  PROFESSIONAL_AGENDA_AVAILABILITY: slug => `/v0/professionals/${slug}/availabilities/`,
  PROFESSIONAL_HISTORY: '/v0/professionals/professional-history/',
  PROFESSIONAL_REVIEW: '/v0/professionals/reviews/',
  PERSONS: '/v0/persons/',
  PERSONS_PROFESSIONALS: '/v0/persons/professionals/',
  PERSONS_PATIENTS: '/v0/persons/patients/',
  PROFESSIONAL_DETAIL: (slug) => `/v0/professionals/${slug}/profile/`,
  PROFESSIONAL_REVIEWS: (professionalUUID) => `/v0/professionals/${professionalUUID}/reviews/`,
  PERSON_SCHEDULE: (slug, profile, scheduleId) =>
    `/v0/persons/${slug}/profiles/${profile}/schedules/${scheduleId}/`,
  PERSON_SLUG: (slug, role) => `/v0/persons/${slug}/?profile_role=${role}`,
  PERSON_PLANS: (slug, profile) => `/v0/persons/${slug}/profiles/${profile}/plans/`,
  PERSON_PROFILE: (slug, profile) => `/v0/persons/${slug}/profiles/${profile}/`,
  PERSON_PROFILES: slug => `/v0/persons/${slug}/profiles/`,
  PERSON_PROFILE_BY_CARD: (cardNumber, planGroupCode) =>
    `/v0/persons/profiles/plans/${cardNumber}/?plan_group_code=${planGroupCode}&profile_role=PACI`,
  PERSON_VALIDITIES: (username, planCode) => planCode
    ? `/v0/persons/${username}/validities/current/?plan_code=${planCode}`
    : `/v0/persons/${username}/validities/current/`,
  PLANS: '/v0/plans/',
  PLANS_GROUPS: '/v0/plans-groups/',
  PROCEDURE_REQUEST: '/v0/treatments/procedure-request/',
  PRODUCTS: '/v0/products/',
  PROFESSIONAL_HOME: '/v0/persons/home/',
  REASONS: reason_group => `/v0/reasons/?reason_group=${reason_group ? reason_group : ''}`,
  REPORTS: type => `/v0/reports/${type}/`,
  SCHEDULES: '/v0/schedules/',
  SCHEDULE_DETAIL: scheduleId => `/v0/schedules/${scheduleId}/`,
  PROFESSIONAL_AGENDA_EVENTS: username => `/v0/persons/${username}/agenda/events/`,
  SCHEDULE_LOGS: hash => `${meetApiUrl}/v0/virtual-rooms/${hash ? hash : ''}/logs/`,
  SKILLS: profileRole => `/v0/skills/?profile_role=${profileRole}`,
  STATUS: '/v0/status/',
  SURVEY: code => `/v0/surveys/${code}/`,
  SURVEY_ANSWER: code => `/v0/surveys/${code}/answers/`,
  VALIDITIES: '/v0/treatments/validities/',
  VALIDITY_ATTACH: id => `/v0/treatments/validities/${id}/attach/`,
  VALIDITY: id => `/v0/treatments/validities/${id}/`,
  PERSON_FACE_ID: `${mainUrl}/v0/authentication/faceid/`,
  DISAPPROVE_DETAILS: id => `/v0/treatments/validities/${id}/reasons/`,
  DELETE_RESERVATION: '/v0/reservations/',
  RESERVATIONS: (username, isProfessional) => `/v0/reservations/${isProfessional ? 'professional' : 'patient'}/${username}/`,
  CORPORATE_ELIGIBILITY: username => `/v0/corporate/${username}/eligibility/`,
  METRICS_PERSONS_TOTALS: 'v0/metrics/persons/totals/',
  METRICS_SCHEDULES: '/v0/metrics/schedules/',
  METRICS_SCHEDULE_AVAILABILITIES: '/v0/metrics/agenda/availabilities/',
  METRICS_PATIENTS_SCHEDULE_STATUS: '/v0/metrics/patients/schedule-status/',
  METRICS_PROFESSIONALS_SCHEDULE_AVERAGE: '/v0/metrics/professionals/schedule-average/',
  DOWNLOAD_MEDICAL_REQUEST: `${medicalRequestsApiUrl}/download`,
  PATIENT_TREATMENT_STATUS: (username) => `/v0/patients/${username}/scheduling-cycle/`,
  LINK_PRIVATE_PLAN: (username) => `v0/patients/${username}/private-plan/`,
}

export let languageCode = () => {
  let language = 'pt-BR'
  return language
}
