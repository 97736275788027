import { AxiosError } from 'axios'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface GetTreatmentStatusProps {
  userName: string
  consultationType: string
  checkEligibility?: boolean
  planCode?: string
}

interface PatientTreatmentStatusData {
  cycleStatus: string
  cycleDate: string | null
}

interface Response {
  data: {
    status: string
    date: string | null
  }
}

const getTreatmentStatus = ({
  userName,
  consultationType = 'PSIQ',
  checkEligibility = false,
  planCode
}: GetTreatmentStatusProps): Promise<PatientTreatmentStatusData> => {

  const endpoint = apiEndpoints.PATIENT_TREATMENT_STATUS(userName)

  return new Promise<PatientTreatmentStatusData> ((resolve, reject) => {
    const params = {
      consultation_type: consultationType,
      check_eligibility: checkEligibility,
      ...(planCode && { plan_code: planCode }),
    }
    axios.get(endpoint, { params })
      .then((response: Response) => {
        resolve({
          cycleStatus: response.data.status,
          cycleDate: response.data.date
        })
      })
      .catch(((err: AxiosError) => reject(err?.response?.status)))
  })
}

export {
  getTreatmentStatus
}