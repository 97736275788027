import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  addMonths,
  endOfMonth,
  eachMonthOfInterval,
  startOfMonth,
  format,
} from 'date-fns'

import AdminScheduleContainer from '../../../AdminScheduleContainer/AdminScheduleContainer'
import ScheduleFeedback from '../../../ScheduleFeedback/ScheduleFeedback'
import { formatCpf, getRoleFromProfile } from 'utils/textTransformations'

import { PortalModal } from 'components/PortalModal/PortalModal'
import Dropdown from 'components/Dropdown'

import { listProfessionalService } from 'services/listProfessionalService'
import { getCorporateEligibility } from 'services/corporateEligibilityService'
import { getProfessionalAvailability } from 'services/professionalAvailabilityService'
import ReservationService from 'services/reservation'
import createOrder from 'services/order/createOrder'
import { getLastValidityDetail } from 'services/lastValidityDetail'
import { getTreatmentStatus } from 'services/treatments'
import createCheckout from 'services/order/createCheckout'

import {  saveOrder } from 'redux/actions/ordersActions'

import axios from 'settings/axios'
import { PSYCHOLOGIST, PSYCHIATRIST } from 'settings/_profileSettings'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'
import { Spinner } from '@telavita-core/react-design-kit'
import { ACTIVE, INACTIVE } from 'settings/_personSettings'
import { apiEndpoints } from 'settings/_apiSettings'

import { getProducts } from 'redux/actions'
import { CONSULTATION_SCHEDULED, INELIGIBLE, MEDICAL_RETURN_SCHEDULED } from 'settings/_patientTreatmentStatus'

const ModalAdminSchedule = ({ patient, onClose }) => {
  const dispatch = useDispatch()

  const [step, setStep] = useState('SCHEDULE')
  const [reservations, setReservations] = useState([])
  const [availabilities, setAvailabilities] = useState([])
  const [schedules, setSchedules] = useState([])
  const [createdSchedules, setCreatedSchedules] = useState([])
  const [loadingProfessionals, setLoadingProfessionals] = useState(false)
  const [loadingSubmitSchedule, setLoadingSubmitSchedule] = useState(false)
  const [loadingAvailabilities, setLoadingAvailabilities] = useState(false)
  const [professionals, setProfessionals] = useState(null)
  const [createdOrder, setCreatedOrder] = useState(null)
  const [hasCheckout, setHasCheckout] = useState(null)
  const [selectedProfessional, setSelectedProfessional] = useState({})
  const [error, setError] = useState(null)

  const [selectedGroupPlan, setSelectedGroupPlan] = useState()
  const [selectedPlan, setSelectedPlan] = useState()
  const [isSchedulingWithParticularPlan, setIsSchedulingWithParticularPlan] = useState(false)

  const [maxSchedules, setMaxSchedules] = useState(null)
  const [planGroupFrequency, setPlanGroupFrequency] = useState(null)
  const [
    showTreatmentNotStartedMessage,
    setShowTreatmentNotStartedMessage,
  ] = useState()
  const [eligibilityLoading, setEligibilityLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [psychiatryTreatmentStatusMessage, setPsychiatryTreatmentStatusMessage] = useState('')
  const [selectedSpeciality, setSelectedSpeciality] = useState(null)

  useEffect(() => {
    setPsychiatryTreatmentStatusMessage('')
  }, [selectedPlan, selectedGroupPlan])

  useEffect(() => {
    setShowTreatmentNotStartedMessage(false)
  }, [selectedSpeciality])

  const patientPlansWithoutParticular = plans.filter(plan => !plan.is_particular)
  const patientParticularPlan = plans.find(plan => plan.is_particular)

  const patientPlansOrdered = patientPlansWithoutParticular
    ? patientPlansWithoutParticular.reduce((acc, curr) => {
      const isPlanGroupAlreadySaved = acc.some(plan => plan.id === curr.plan_group.code)

      if (!isPlanGroupAlreadySaved && curr.status !== INACTIVE) {
        acc.push({
          id: curr.plan_group.code,
          name: curr.plan_group.name,
        })
      }

      return acc
    }, []).sort((a, b) => (a.name > b.name) ? 1 : -1)
    : []

  const patientPlansGroup = [...patientPlansOrdered, {
    id: patientParticularPlan?.plan_group.code,
    name: patientParticularPlan?.name
  }]


  const patientPlans = plans 
    ? plans.reduce((acc, curr) => {
      if (curr.plan_group.code === selectedGroupPlan && curr.status !== INACTIVE) {
        acc.push({
          id: curr.plan_code,
          name: curr.name,
        })
      }
      return acc
    }, []).sort((a, b) => (a.name > b.name) ? 1 : -1)
    : []


  const [
    fetchedAvailabilitiesMonths,
    setFetchedAvailabilitiesMonths,
  ] = useState([])

  const fromMonth = new Date()
  const toMonth = endOfMonth(addMonths(new Date(), 3))
  const products = useSelector((state) => state.products.products)

  const selectedProduct = products[0]

  const {
    ENTERPRISE,
    CARE_PLUS,
    CORPORATE_LIMITED,
    CORPORATE_UNLIMITED,
  } = PlanGroupCategoryEnum

  useEffect(() => {
    dispatch(getProducts(PSYCHOLOGIST))
  }, [])

  useEffect(() => {
    const fetchPatientPlans = async () => {
      const fetchUrl = apiEndpoints.PATIENTS_PLANS(patient.person.username)
      const response = await axios.get(fetchUrl)
      let plans = response.data.filter(plan => plan.status !== INACTIVE)

      const patientHasParticularPlan = plans.some(plan => plan.is_particular)

      if (!patientHasParticularPlan) {
        const response = await axios.post(apiEndpoints.LINK_PRIVATE_PLAN(patient.person.username))
        plans = [...plans, {
          plan_group: response.data.plan_group,
          plan_code: response.data.partner_plan_code,
          name: response.data.partner_plan_name
        }] 
      }

      setPlans(plans)
    }

    fetchPatientPlans()
  },[])

  const getProfessionals = (specialty, query) => {
    setLoadingProfessionals(true)

    setAvailabilities([])
    setFetchedAvailabilitiesMonths([])

    listProfessionalService({
      profileRole: specialty.code,
      statusCode: ACTIVE,
      planCode: selectedPlan.plan_code,
      query: query,
    }).then((res) => {
      setLoadingProfessionals(false)
      setProfessionals(res.persons)
    })
  }

  const onSubmitSchedule = async (availabilities, professional) => {
    setLoadingSubmitSchedule(true)
    setSelectedProfessional(professional)

    const payload = {
      product_group_code:
        professional.person_profile[0].products[0].product.product_group.code,
      plan_code: selectedPlan.plan_code,
      profile_role: patient.profileRole,
      username: patient.person.username,
      person_profile_product_appointment_id:
        professional.person_profile[0].products[0].appointments[0].id,
      schedules: availabilities.map((availability) => {
        return {
          start_date: availability.startDate,
          end_date: availability.endDate,
          guests: [
            {
              person_profile_id: professional.person_profile[0].id,
            },
            {
              person_profile_id: patient.person.person_profile_id,
            },
          ],
        }
      }),
    }

    await dispatch(saveOrder(payload))
    const { orderId, instantCheckout } = await createOrder(payload)
    const checkoutResponse = await createCheckout({
      orderId,
    })

    if (orderId && checkoutResponse === 'OK') {
      setCreatedOrder(orderId)
      setCreatedSchedules(
        availabilities.map((availability) => availability.startDate)
      )
      setHasCheckout(instantCheckout)
    }

    setLoadingSubmitSchedule(false)
    setStep('SUCCESS')
  }

  const onSelectProfessional = async (professional) => {
    if (!professional) return

    setLoadingAvailabilities(true)
    setAvailabilities([])
    setSchedules([])
    setError(null)
    setReservations([])

    ReservationService.getReservations({
      username: patient.person.username,
      isProfessional: true,
    })
      .then((reservations) => {
        setReservations(reservations)
      })
      .catch((error) => {
        console.error(error)
        setReservations([])
      })

    const profileRole = getRoleFromProfile(professional?.person_profile[0].profile_code)

    if (profileRole === PSYCHIATRIST && !isSchedulingWithParticularPlan) {
      await handlePsychiatryTreatmentStatusMessage()
    }

    if (profileRole === PSYCHOLOGIST) {
      await handleCheckEligibility()
    }

    await getAvailabilities(professional)
  }

  const handlePsychiatryTreatmentStatusMessage = async () => {
    const response = await getTreatmentStatus({
      userName: patient.person.username,
      consultationType: 'PSIQ',
      checkEligibility: false,
      planCode: selectedPlan.plan_code,
    })
    if (response) {
      const { cycleDate, cycleStatus } = response
      const formatedCycleDate = cycleDate ? format(new Date(cycleDate), 'dd/MM/yyyy') : 'data não definida'
      const messages = {
        [CONSULTATION_SCHEDULED]: 'Paciente já possui consulta agendada.',
        [INELIGIBLE]: 'Paciente está inelegível.',
        [MEDICAL_RETURN_SCHEDULED]: `
        Paciente já possui retorno médico agendado,
        só é possível agendar uma nova consulta a partir de ${formatedCycleDate}.`
      }
      setPsychiatryTreatmentStatusMessage(messages[cycleStatus] || '')
    }
  }

  const getAvailabilities = async (professional) => {
    const interval = eachMonthOfInterval({ start: fromMonth, end: toMonth })

    for await (let month of interval) {
      await getProfessionalAvailability({
        slug: professional.person_general.email,
        startDate: startOfMonth(month).toISOString(),
        endDate: endOfMonth(month).toISOString(),
        planCode: selectedPlan.plan_code,
        productCode: professional.person_profile[0].products[0].product.code,
        username: patient.person.username,
        profileRole: getRoleFromProfile(
          professional.person_profile[0].profile_code
        ),
        isParticular: isSchedulingWithParticularPlan,
      })
        .then(({ availabilities, schedules }) => {
          setFetchedAvailabilitiesMonths((prev) => [...prev, month.getMonth()])
          setLoadingAvailabilities(false)
          setAvailabilities((prev) => [...prev, ...availabilities])
          setSchedules((prev) => [...prev, ...schedules])
        })
        .catch((error) => {
          setLoadingAvailabilities(false)
          setAvailabilities([])
          setSchedules([])
          setError(error)
        })
    }
  }

  const handleCheckEligibility = async () => {
    const currentPlan = selectedPlan
    setEligibilityLoading(true)
    const planGroupCategory = currentPlan.plan_group.category
    const isCorporate = [CORPORATE_LIMITED, CORPORATE_UNLIMITED].includes(
      planGroupCategory
    )
    const isCorporateUnlimited = planGroupCategory === CORPORATE_UNLIMITED
    const isEnterprise = [CARE_PLUS, ENTERPRISE].includes(planGroupCategory)


    if (isCorporate) {
      getCorporateEligibility({
        username: patient.person.username,
        planCode: currentPlan.plan_code
      }).then((res) => {
        setEligibilityLoading(false)

        setPlanGroupFrequency(res?.frequency) 

        if (isCorporateUnlimited) return

        setMaxSchedules(res.availableCredits)
      }).catch(() => setEligibilityLoading(false))
    } else if (isEnterprise) {
      getLastValidityDetail(patient.person.email, currentPlan.plan_code)
        .then((validityDetail) => {
          setMaxSchedules(validityDetail.availableCredits)
          setEligibilityLoading(false)
          setShowTreatmentNotStartedMessage(false)
        })
        .catch((error) => {
          if (error === 404) setShowTreatmentNotStartedMessage(true)
          setEligibilityLoading(false)
          console.error(error)
        })
    }
    setEligibilityLoading(false)
  }

  const handleSelectPlan = (id) => {
    setAvailabilities([])
    setSchedules([])
    setError(null)
    setReservations([])
    setShowTreatmentNotStartedMessage(false)
    setMaxSchedules(null)

    const currentPlan = plans.find(
      (plan) => plan.plan_code === id
    )

    setSelectedPlan(currentPlan)
  }

  const handleSelectGroupPlan = (id) => {
    setSelectedPlan(null)
    setAvailabilities([])
    setSchedules([])
    setError(null)
    setReservations([])
    setSelectedGroupPlan(id)
    setIsSchedulingWithParticularPlan(false)

    const currentPlan = plans.find(
      (plan) => plan.plan_group.code === id
    )

    if (currentPlan.is_particular) {
      handleSelectPlan(currentPlan.plan_code)
      setIsSchedulingWithParticularPlan(true)
      return
    }

  }

  const onMonthChange = (date) => {
    if (!fetchedAvailabilitiesMonths.includes(date.getMonth()))
      setLoadingAvailabilities(true)
  }

  const recurrentReservation = reservations?.find(reservation => reservation.recurrence === 'Semanal')

  return (
    <PortalModal
      title={step === 'SUCCESS' ? 'Agendamento concluído' : 'Agendar consulta'}
      subtitle={
        <>
          <div>
            Para <b>{patient?.person?.full_name || ''}</b>
          </div>
          <div>CPF {formatCpf(patient?.person?.cpf || '')}</div>
        </>
      }
      onClose={onClose}
    >
      <div className='ModalAdminSchedule'>
        <div className='ModalAdminSchedule__Content'>
          {!createdOrder && (
            <>
              <div className='ModalAdminSchedule__Content__DropdownWrapper'>
                <Dropdown
                  dropDownOverflow
                  dropDownItensToShow='3'
                  name='group-plan-list'
                  options={patientPlansGroup}
                  onSelect={(id) => handleSelectGroupPlan(id)}
                  placeholder='Forma de agendamento'
                />
                {selectedGroupPlan && !isSchedulingWithParticularPlan && (
                  <Dropdown
                    dropDownOverflow
                    dropDownItensToShow='3'
                    name='plan-list'
                    options={patientPlans}
                    clearSelection={selectedPlan === null}
                    onSelect={(id) => handleSelectPlan(id)}
                    placeholder='Selecione o plano'
                  />
                )}
                {eligibilityLoading && <div className='ModalAdminSchedule__Content__LoadingContainer'><Spinner /></div>}
                
              </div>
              {selectedPlan &&
                (
                  <AdminScheduleContainer
                    frequency={planGroupFrequency}
                    professionals={professionals}
                    availabilities={availabilities}
                    schedules={schedules}
                    reservationDate={
                      recurrentReservation && !error && recurrentReservation.start_date
                    }
                    reservationExceptions={
                      recurrentReservation && recurrentReservation?.recurrency_exceptions
                    }
                    error={error}
                    loading={loadingAvailabilities}
                    loadingSubmit={loadingSubmitSchedule}
                    loadingProfessionals={loadingProfessionals}
                    onSearch={getProfessionals}
                    onSubmit={onSubmitSchedule}
                    onSelectProfessional={onSelectProfessional}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    maxSchedules={maxSchedules}
                    enableNewPatients={
                      selectedProfessional
                        ? selectedProfessional.enable_new_patients
                        : false
                    }
                    onMonthChange={onMonthChange}
                    psychiatryTreatmentStatusMessage={psychiatryTreatmentStatusMessage}
                    eligibilityLoading={eligibilityLoading}
                    showTreatmentNotStartedMessage={showTreatmentNotStartedMessage}
                    selectedSpeciality={selectedSpeciality}
                    setSelectedSpeciality={setSelectedSpeciality}
                  />
                )}
            </>
          )}
          {createdOrder && (
            <ScheduleFeedback
              orderId={createdOrder}
              hasCheckout={hasCheckout}
              schedules={createdSchedules}
              professional={selectedProfessional}
              onClose={onClose}
            />
          )}
        </div>
      </div>
    </PortalModal>
  )
}

export default ModalAdminSchedule
