import React from 'react'
import { Trans } from '@lingui/macro'
import { Typography, PageTop, Column, TextInput, RadioButton, Button } from 'components'
import './NewPlanGroup.sass'
import Row from 'components/Row'
import { useState } from 'react'
import InputValidation from 'utils/InputValidation'
import { createPlanGroup } from 'redux/actions/plansActions'
import { useHistory } from 'react-router-dom'
import * as routes from '../../../../settings/_routesSettings'
import Dropdown from '../../../../components/Dropdown/index'
import { PLANS_TIERS } from 'settings/_tiersSettings'
import { PopUpCalendar } from 'components/PopUpCalendar'
import { format } from 'date-fns'

const validateInput = (key, value) => {
  switch (key) {
  case 'name': return value.length < 128
  case 'planName': return value.length < 128
  case 'amountCredits': return !isNaN(value)
  case 'expirationDays': return !isNaN(value)
  default: return false
  }
}

const initialState = {
  name: {
    value: '',
    isValid: true
  },

  picture: {
    value: null,
    isValid: true,
    name: null,
    message: ''
  },

  planName: {
    value: 'Padrão',
    isValid: true
  },

  amountCredits: {
    value: '',
    isValid: true
  },
  endDateProgram: {
    value: [],
    isValid: true
  },

  frequency: null,

  isLimited: null,

  noShow: null,

  faceID: null,

  reservation: null,

}

export const NewPlanGroup = () => {

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(() => initialState)
  const [extraPlans, setExtraPlans] = useState({})
  const [showPopUpCalendar, setShowPopUpCalendar] = useState(false)

  const frequencyOptions = [
    { code: 1, name: '1 semana' },
    { code: 2, name: '2 semanas' },
    { code: 12, name: '12 semanas' }
  ]

  const tierOptions = [
    ...PLANS_TIERS.CORPORATE,
    ...PLANS_TIERS.ENTERPRISE,
    ...PLANS_TIERS.CAPITATION,
  ]

  const onSelectTier = (key, tier) => {
    setState(s => ({
      ...s,
      [key]: {
        ...s[key],
        tier,
      }
    }))
  }

  const onSelectExtraPlansTier = (key, tier) => {
    setExtraPlans(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        tier
      }
    }))
  }

  const onChangeTextInput = (key, value) => {
    setState(s => ({
      ...s,
      [key]: {
        ...s[key],
        value,
        isValid: validateInput(key, value)
      }
    }))
  }

  const onChangeNumericInput = (key, value) => {
    if (isNaN(+value) || +value < 0)
      value = 0

    setState(s => ({
      ...s,
      [key]: {
        value,
        isValid: validateInput(key, value)
      }
    }))
  }

  const onToggleNoShow = bool => {
    setState(s => ({
      ...s,
      noShow: bool
    }))
  }

  const onToggleIsLimited = bool => {
    setState(state => ({
      ...state,
      isLimited: bool
    }))
  }

  const onToggleFaceID = bool => {
    setState(s => ({
      ...s,
      faceID: bool
    }))
  }

  const handleFrequencyOptions = code => {
    setState(state => ({
      ...state,
      frequency: code
    }))
  }


  const onToggleReservation = bool => {
    setState(s => ({
      ...s,
      reservation: bool
    }))
  }

  const onChangeImage = async e => {
    const getFile = e.target.files
    const imgName = getFile[0] ? getFile[0].name : state.picture.name

    const validation = new InputValidation(getFile, 'image')
    const imageIsValid = await validation.validate()

    setState(s => ({
      ...s,
      picture: {
        name: imgName,
        value: imageIsValid.encodedImage,
        isValid: imageIsValid.success,
        message: imageIsValid.message
      }
    }))
  }

  const onSubmit = async () => {
    let planList = Object.keys(extraPlans).map(e => ({
      name: extraPlans[e].value,
      tier: extraPlans[e].tier
    }))
    planList.push({
      name: state.planName.value,
      tier: state.planName.tier,
    })

    let payload = {
      'group_name': state.name.value,
      'logo': state.picture.value,
      'include_no_show': state.noShow,
      'require_faceid': state.faceID,
      'frequency': state.frequency,
      'category': state.isLimited ? 'CORPORATE_LIMITED' : 'CORPORATE_UNLIMITED',
      'allows_reservation': state.reservation,
      plans: planList
    }

    if (state.isLimited) {
      payload = {
        ...payload,
        'max_appointment': +state.amountCredits.value,
        'end_date_program': format(state.endDateProgram.value[0], 'yyyy-MM-dd'),
      }
    }

    setLoading(true)

    createPlanGroup(payload)
      .then(() => {
        setLoading(false)
        history.push(routes.DASHBOARD_PLAN_GROUP_LIST_VIEW)
      })
      .catch(() =>{
        setLoading(false)
        setState(initialState)
      })
  }

  const handleAddPlan = e => {
    e.preventDefault()

    // TODO: Melhorar implementação da geração de chaves dos campos
    const index = Math.random(0, 99999)

    setExtraPlans({
      ...extraPlans,
      [index]: {
        value: '',
        isValid: true,
      }
    })
  }

  const handleRemoveExtraPlan = (key) => {
    const { [key]: removed, ...rest } = extraPlans
    setExtraPlans(rest)
  }

  const onChangeExtraPlanValue = (key, value) => {
    setExtraPlans((prevState) => ({
      ...extraPlans,
      [key]: {
        ...prevState[key],
        value: value,
        isValid: validateInput('planName', value),
      }
    }))
  }

  const isValid = () => {
    const ignoredKeys = ['noShow', 'faceID', 'picture', 'reservation', 'isLimited', 'frequency']
    const { noShow, faceID, reservation, isLimited, frequency, planName} = state
    //The keys to be checked in validation

    if (!isLimited) {
      ignoredKeys.push('amountCredits', 'endDateProgram')
    }

    const keys = Object.keys(state).filter(k => !ignoredKeys.includes(k))
    const hasPicture = Boolean(state.picture.value)
    const allChecked = noShow !== null && faceID !== null && reservation !== null && frequency !== null && isLimited !== null
    const planNameIsValid = planName.isValid && !!planName.value && !!planName?.tier
  
    const validations = keys.map(k => {
      const input = state[k]
      if (!input.value) return false

      if (Object.keys(extraPlans).length !== 0) {
        const plansValue = Object.keys(extraPlans).map(plan => extraPlans[plan].value)
        const plansTier = Object.keys(extraPlans).map(plan => extraPlans[plan]?.tier)
        const plansValid = Object.keys(extraPlans).map(plan => extraPlans[plan].isValid)
        const plansIsValid = plansValid.every(e => e === true)

        if (plansValue.some(planValue => !planValue) || plansValue.some(planValue => planValue === '')) return false
        if (plansTier.some(planTier => !planTier)) return false

        return plansIsValid && input.isValid
      } else {
        return input.isValid
      }
    }).concat(hasPicture) //Concatenating the boolean assertion of base64

    return allChecked && planNameIsValid && validations.reduce((p, c) => p && c)
  }

  const handleChangeCalendarDate = (date) => {
    setState(prevState => ({
      ...prevState,
      endDateProgram: {
        value:[date],
        isValid: true
      }
    }))
    closePopUpCalendar()
  }

  const closePopUpCalendar = () => setShowPopUpCalendar(false)

  const renderCreditsOptions = () => {
    return (
      <>
        <div className="NewPlanGroup__CreditsRow">
          <div className="NewPlanGroup__CreditsOptions">
            <div className="NewPlanGroup__InputContainer">
              <Typography
                Tag="h2"
                align="right"
              >
                Quantas?*
              </Typography>
              <input
                maxLength="3"
                value={state.amountCredits.value}
                onChange={e => onChangeNumericInput('amountCredits', e.target.value)}
              />
            </div>
            <div className="NewPlanGroup__InputContainer">
              <Typography
                Tag="h2"
                align="left"
                onClick={() => setShowPopUpCalendar(true)}
              >
                Programa termina em:* 
              </Typography>
              <div className='NewPlanGroup__DateContainer' onClick={() => setShowPopUpCalendar(true)}>
                {state.endDateProgram.value.length <= 0 ? 'dd/mm/aaaa': `${format(state.endDateProgram.value[0], 'dd/MM/yyyy')}`}
              </div>
              {showPopUpCalendar && (
                <PopUpCalendar
                  allowPartialRange={false}
                  allowRange={false}
                  onClickOutside={closePopUpCalendar}
                  handleClose={closePopUpCalendar}
                  visible={showPopUpCalendar}
                  handleChangeDate={handleChangeCalendarDate}
                  selectedDate={state.endDateProgram.value}
                  onApplyDate={closePopUpCalendar}
                  placement='bottom-end'
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }


  return (
    <div>
      <div className="ProfessionalsList__header" style={{ 'width': '100%' }}>
        <PageTop
          id="professionals-view-header"
          toHideOnMobile={(
            <Typography align='mcenter-dleft' Tag='h1' type='title' color='grey-twelve'>
              <Trans>Adicionar empresa</Trans>
            </Typography>
          )}
          toShowOnMobile={(<></>)}
        />
      </div>
      <div className="NewPlanGroup__Container">
        <Row padding="30px 0 15px 0">
          <Typography
            Tag="h2"
            align="left">
            <Trans>
              Regras do convênio*
            </Trans>
          </Typography>
        </Row>

        <Row>
          <Column colWidth={6}>
            <TextInput
              placeholder="Nome do convênio*"
              required={true}
              name="name"
              hasError={!state.name.isValid}
              inputHeight='4.8rem'
              messageError={'O nome deve conter no máximo 128 caracteres'}
              onInputChange={e => onChangeTextInput('name', e.target.value)}
              value={state.name.value}
            />
          </Column>
          <Column colWidth={6}>
            <div className="RegisterBlock__MediaData--image-wrapper">
              <TextInput
                addIcon={true}
                value={state.picture.name || ''}
                onChange={() => { }}
                readOnly
                placeholder='Logo do convênio (130x70px)*'
                name="picture"
                hasError={!state.picture.isValid}
                iconProps={{ fill: '#898989', height: '20px', name: 'UploadFile', width: '20px' }}
                inputHeight='4.8rem'
                messageError={state.picture.message}
              />
              <input id="hiddenInput-file" type='file' onChange={onChangeImage} />
            </div>
          </Column>
        </Row>
        <Row padding="40px 0 15px 0">
          <Typography
            Tag="h2"
            align="left">
            <Trans>
              Nome do plano*
            </Trans>
          </Typography>
        </Row>

        <Row>
          <Column colWidth={6}>
            <Row Row className="row__addPlanRow">
              <TextInput
                placeholder=""
                required={true}
                name="planName"
                hasError={false}
                inputHeight='4.8rem'
                messageError={'O nome deve conter no máximo 128 caracteres'}
                onInputChange={e => onChangeTextInput('planName', e.target.value)}
                value={state.planName.value}
              />
              <Dropdown
                name='frequency'
                onSelect={(data) => onSelectTier('planName', data)}
                options={tierOptions}
                placeholder={'Selecione'}
              />
            </Row>

            {Object.keys(extraPlans).map(key => (
              <Row className="row__addPlanRow" key={key}>
                <TextInput
                  placeholder="Insira o nome"
                  required={true}
                  name={key}
                  inputHeight='4.8rem'
                  messageError={'O nome deve conter no máximo 128 caracteres'}
                  hasError={!extraPlans[key].isValid}
                  onInputChange={e => onChangeExtraPlanValue(key, e.target.value)}
                />
                <Dropdown
                  name='frequency'
                  onSelect={(data) => onSelectExtraPlansTier(key, data)}
                  options={tierOptions}
                  placeholder={'Selecione'}
                />
                <span className="row__addPlanX" onClick={() => handleRemoveExtraPlan(key)} />
              </Row>
            ))}
          </Column>
        </Row>

        <Row>
          <button className="NewPlanGroup__addPlanButton" onClick={handleAddPlan}>
            INCLUIR OUTRO PLANO
          </button>
        </Row>

        <Row padding="40px 0 0 0">
          <Typography
            Tag="h2"
            align="left">
            <Trans>
                            Regras do convênio*
            </Trans>
          </Typography>
        </Row>

        <Row padding="15px 0 0 0">
          <div className="NewPlanGroup__InnerContainer">

            <div className="NewPlanGroup__RenewRow">
              <div className="NewPlanGroup__RenewTitle">
                <Typography
                  Tag="h2"
                  align="left"
                >
                    1 consulta a cada:*
                </Typography>
              </div>
              <div className="NewPlanGroup__RenewRadios">
                <Dropdown
                  name='frequency'
                  onSelect={handleFrequencyOptions}
                  options={frequencyOptions}
                  placeholder={'Selecione'}
                />
              </div>
            </div>


            <div className={state.isLimited !== null && state.isLimited ? 'NewPlanGroup__RenewCreditsRow' : 'NewPlanGroup__RenewRow'}>
              <div className="NewPlanGroup__RenewTitle" >
                <Typography
                  Tag="h2"
                  align="left"
                >
                  Possui limite de consultas?*
                </Typography>
              </div>
              <div className="NewPlanGroup__RenewRadios">
                <RadioButton
                  title={'Sim'}
                  onClickRadioButton={() => onToggleIsLimited(true)}
                  radioId={'yes-radio'}
                  selected={state.isLimited === true}
                  value={1}
                  inlineStyles={{ marginRight: 20 }}
                  inlineIconStyle={{ marginRight: 7 }}
                />
                <RadioButton
                  title={'Não'}
                  onClickRadioButton={() => onToggleIsLimited(false)}
                  radioId={'no-radio'}
                  selected={state.isLimited === false}
                  value={1}
                  inlineIconStyle={{ marginRight: 7 }}
                />
              </div>
            </div>
            { state.isLimited && renderCreditsOptions() }

            <div className="NewPlanGroup__RenewRow">
              <div className="NewPlanGroup__RenewTitle">
                <Typography
                  Tag="h2"
                  align="left"
                >
                                    Paga <i>no-show</i>?*
                </Typography>
                <span>Quando o paciente não comparece na consulta</span>
              </div>
              <div className="NewPlanGroup__RenewRadios">
                <RadioButton
                  title={'Sim'}
                  onClickRadioButton={() => onToggleNoShow(true)}
                  radioId={'yes-noshow-radio'}
                  selected={state.noShow === true}
                  value={1}
                  inlineStyles={{ marginRight: 20 }}
                  inlineIconStyle={{ marginRight: 7 }}
                />
                <RadioButton
                  title={'Não'}
                  onClickRadioButton={() => onToggleNoShow(false)}
                  radioId={'no-noshow-radio'}
                  selected={state.noShow === false}
                  value={1}
                  inlineIconStyle={{ marginRight: 7 }}
                />
              </div>
            </div>
            <div className="NewPlanGroup__RenewRow" >
              <div className="NewPlanGroup__RenewTitle">
                <Typography
                  Tag="h2"
                  align="left"
                >
                  <Trans>
                                        Reconhecimento facial*
                  </Trans>
                </Typography>
              </div>
              <div className="NewPlanGroup__RenewRadios">
                <RadioButton
                  title={'Sim'}
                  onClickRadioButton={() => onToggleFaceID(true)}
                  radioId={'yes-radio'}
                  selected={state.faceID === true}
                  value={1}
                  inlineStyles={{ marginRight: 20 }}
                  inlineIconStyle={{ marginRight: 7 }}
                />
                <RadioButton
                  title={'Não'}
                  onClickRadioButton={() => onToggleFaceID(false)}
                  radioId={'no-radio'}
                  selected={state.faceID === false}
                  value={1}
                  inlineIconStyle={{ marginRight: 7 }}
                />
              </div>
            </div>
            <div className="NewPlanGroup__RenewRow" style={{ border: 'none' }}>
              <div className="NewPlanGroup__RenewTitle">
                <Typography
                  Tag="h2"
                  align="left"
                >
                  <Trans>
                                        Reserva de horário*
                  </Trans>
                </Typography>
              </div>
              <div className="NewPlanGroup__RenewRadios">
                <RadioButton
                  title={'Sim'}
                  onClickRadioButton={() => onToggleReservation(true)}
                  radioId={'yes-radio'}
                  selected={state.reservation === true}
                  value={1}
                  inlineStyles={{ marginRight: 20 }}
                  inlineIconStyle={{ marginRight: 7 }}
                />
                <RadioButton
                  title={'Não'}
                  onClickRadioButton={() => onToggleReservation(false)}
                  radioId={'no-radio'}
                  selected={state.reservation === false}
                  value={1}
                  inlineIconStyle={{ marginRight: 7 }}
                />
              </div>
            </div>
          </div>
        </Row>

      </div>
      <div className="NewPlanGroup__BottomContainer">
        <Typography
          Tag="h2"
          align="left"
          italic
          color='grey-ten'
        >
          <Trans>
                        *Campos de preenchimento obrigatório
          </Trans>
        </Typography>
        <Button
          extraClass="NewPlanGroup__Button"
          onButtonClick={onSubmit}
          disabled={!isValid()}
          loading={loading}
        >
                    ADICIONAR E PUBLICAR EMPRESA
        </Button>
      </div>
    </div>
  )
}
