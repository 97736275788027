import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { hideModal } from 'redux/actions'
import { history } from 'routers/AppRouter'

import axios from 'settings/axios'
import { planGroupsSettings } from 'settings/_planGroupSettings'
import { apiEndpoints } from 'settings/_apiSettings'
import { DASHBOARD_EDIT_USER } from 'settings/_routesSettings'
import { PATIENT } from 'settings/_profileSettings'

import ModalGeneric from '../ModalGeneric'

import NotificationStep from 'components/NotificationStep'
import Dropdown from 'components/Dropdown'
import Textarea from 'components/Textarea'

import { Typography, Button, Spinner, TextInput } from '@telavita-core/react-design-kit'

const PersonProfilePlanReasons = {
  UPDATE_CARD_NUMBER: 'Atualização de carteirinha',
  UPDATE_PLAN: 'Atualização de plano',
  INCORRECT_CARD_NUMBER: 'Carteirinha incorreta',
  INCORRECT_PLAN_GROUP: 'Convênio incorreto',
  PLAN_GROUP_CHANGE: 'Mudança de convênio',
  INCORRECT_PLAN: 'Plano incorreto',
  OTHERS: 'Outros',
}

const OptionsGroupBradesco = {
  BRADESCO_SAUDE: 'Bradesco Saúde',
  BRADESCO_SEGUROS: 'Bradesco Seguros',
}

const OptionsGroupPse = {
  ALEXION: 'Alexion',
  BMC: 'BMC',
  BANCO_MIZUHO: 'Banco Mizuho',
  BURGER_KING: 'Burger King',
  CATA_CLINICA_DE_ANESTESIA: 'Cata Clínica de Anestesia',
  CHEP_DO_BRASIL: 'Chep do Brasil',
  CITRIX: 'Citrix',
  DISNEY: 'Disney', 
  DPZeT: 'DPZ&T',
  DYNATRACE: 'Dynatrace', 
  ECOM_ENERGIA: 'Ecom Energia',
  FANATEE: 'Fanatee',
  FCB_BRASIL: 'FCB BRASIL',
  IDC: 'IDC',
  INPEV: 'inpEV',
  LINKEDIN: 'Linkedin',
  MEDCORP: 'Medcorp',
  PEPSICO: 'PEPSICO',
  RED_HAT: 'Red Hat',
  SOFTWARE_AG: 'Software AG',
  WMCCANN: 'WMcCANN',
}

const OMINT = 'Omint'

const EditPlanModal = ({
  name,
  username,
  activePlanId,
  planGroupCode,
  activeCardNumber,
}) => {

  const dispatch = useDispatch()

  const plans = useSelector(state => state.plans.plans)
  const reasons = Object.entries(PersonProfilePlanReasons).map(([key, index]) => {
    return {
      code: key,
      name: index,
    }
  })

  const [cardNumber, setCardNumber] = useState(activeCardNumber && activeCardNumber)
  const [planGroupHasCardNumber, setPlanGroupHasCardNumber] = useState(false)
  const [reason, setReason] = useState({})
  const [plan, setPlan] = useState({})
  const [plansOptions, setPlansOptions] = useState([])
  const [details, setDetails] = useState('')
  const [readyToSave, setReadyToSave] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  
  const selectedPlanGroupCode = plansOptions[0]?.planGroupCode

  useEffect(() => {
    if(getCardNumberMask(selectedPlanGroupCode)) {
      setPlanGroupHasCardNumber(true)
      setCardNumber(activeCardNumber)
    } else {
      setPlanGroupHasCardNumber(false)
      setCardNumber('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanGroupCode])

  function hasPlanGroupSettings(planGroupCode) {
    return Object.entries(planGroupsSettings).find(([key]) => key === planGroupCode)
  }

  function removeCardNumberMask(cardNumber) {
    return cardNumber ? cardNumber.replace(/[^0-9]/g, '') : cardNumber
  } 

  function getPlansOptions() {
    const plansOptions = []

    plans.forEach(plan => {
      Object.entries(plan.plan_group).forEach(([key, value]) => {
        if (key === 'code' && value === planGroupCode) {
          plansOptions.push({
            id: plan.id,
            code: plan.id,
            name: plan.name,
          })
        }
      })
    })

    const sortedPlansOptions = plansOptions.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    return sortedPlansOptions
  }

  function getPlanGroupOptions() {
    const planGroupOptions = []
    const planGroupName = getPlanGroupName(activePlanId)

    const isInGroupBradesco = Object.values(OptionsGroupBradesco).includes(planGroupName)
    const isInGroupPse = Object.values(OptionsGroupPse).includes(planGroupName)

    plans.forEach(plan => {
      Object.entries(plan.plan_group).forEach(([key, value]) => {
        if(isInGroupBradesco) {
          if(key === 'name' && Object.values(OptionsGroupBradesco).includes(value)) {
            planGroupOptions.push({
              id: plan.plan_group.id,
              code: plan.plan_group.id,
              name: plan.plan_group.name,
            })
          }
        }
        else if(isInGroupPse) {
          if(key === 'name' && (Object.values(OptionsGroupPse).includes(value) || value === OMINT)) {
            if(key === 'name' && [OMINT, planGroupName].includes(value)) {
              planGroupOptions.push({
                id: plan.plan_group.id,
                code: plan.plan_group.id,
                name: plan.plan_group.name,
              })
            }
          }
        }
        else if(key === 'name' && value === planGroupName){
          planGroupOptions.push({
            id: plan.plan_group.id,
            code: plan.plan_group.id,
            name: plan.plan_group.name,
          })
        }
      })
    })

    const uniquePlanGroupOptions = planGroupOptions.filter((tag, index, array) => 
      array.findIndex(t => t.name === tag.name && t.code === tag.code) === index)

    const sortedPlanGroupOptions = uniquePlanGroupOptions.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    return sortedPlanGroupOptions
  }

  function getPlanName(id) {
    const plan = plans.filter(plan => plan.id === id).map(filterPlan => {
      return filterPlan.name
    })

    return plan[0]
  }

  function getPlanGroupName(planId) {
    const planGroup = plans.filter(plan => plan.id === planId).map(filterPlan => {
      return filterPlan.plan_group.name
    })

    return planGroup[0]
  }

  function getPlanDefault() {
    const defaultPlan = plans.filter(plan => plan.id === activePlanId).map(filterPlan => {
      return filterPlan.id
    })

    return defaultPlan[0]
  }

  function getPlanGroupDefault() {
    const defaultPlanGroup = plans.filter(plan => plan.id === activePlanId).map(filterPlan => {
      return filterPlan.plan_group.id
    })

    return defaultPlanGroup[0]
  }

  function getCardNumberMask(code = planGroupCode) {
    if (!hasPlanGroupSettings(selectedPlanGroupCode)) return
    return Object.entries(planGroupsSettings).find(([key]) => key === code)[1].identifier.mask
  }

  function handleSelectReason(code) {
    const selectedReason = reasons.find(reason => reason.code === code)
    setReason(selectedReason)
  }

  function handleSelectPlan(id) {
    const selectedPlan = plans.filter(plan => plan.id === id).map(filterPlan => {
      return {
        id: filterPlan.id,
        code: filterPlan.code,
        name: filterPlan.name,
        groupName: filterPlan.plan_group.name
      }
    })

    setPlan(selectedPlan[0])
  }

  function handleSelectPlanGroup(planGroupId) {
    const newPlansOptions = []

    plans.forEach(plan => {
      Object.entries(plan.plan_group).forEach(([key, value]) => {
        if (key === 'id' && value === planGroupId) {
          newPlansOptions.push({
            id: plan.id,
            code: plan.id,
            name: plan.name,
            planGroupCode: plan.plan_group.code
          })
        }
      })
    })

    setPlansOptions(newPlansOptions)
  }

  async function handleSubmit() {
    if(!readyToSave) {
      setLoading(true)

      const changeCardNumber = activeCardNumber !== cardNumber
      if(changeCardNumber) {
        const error = await checkCardNumber()
        if(error){
          setLoading(false)
          return
        }
      }

      setReadyToSave(true)

      setLoading(false)

    } else {
      setLoading(true)

      const payload = {
        reason: reason.name,
        more_info: details,
        card_number: cardNumber ? cardNumber : '',
        plan_code: plan.code,
      }

      const fetchUrl = apiEndpoints.PATIENTS_PLAN_EDIT(username)

      const response = await axios.post(fetchUrl, payload)

      const { status } = response

      if(status === 'OK') {
        setSuccess(true)
        setLoading(false)
      }
    }
  }

  function setBtnDisabled() {
    const hasReason = Object.values(reason).length > 0
    
    if(!!activeCardNumber || planGroupHasCardNumber) {
      const hasCardNumber = cardNumber !== ''
      const hasMask = getCardNumberMask(selectedPlanGroupCode)
      const maskLength = hasMask ? removeCardNumberMask(getCardNumberMask(selectedPlanGroupCode)).length : 0
      const inputLength = cardNumber ? cardNumber.length : 0
      const minLength = inputLength === maskLength
      const hasChangeCardNumber = planGroupHasCardNumber ? activeCardNumber !== cardNumber : !planGroupHasCardNumber
      const hasChangePlan = activePlanId !== plan.id

      return !hasCardNumber || !hasReason || error || !minLength || !(hasChangeCardNumber || hasChangePlan)
    }

    return !hasReason
  }

  function setInputDisabled() {
    return activeCardNumber === cardNumber && activePlanId === plan.id
  }

  async function checkCardNumber() {
    const changeCardNumber = activeCardNumber !== cardNumber
    const hasMask = getCardNumberMask(selectedPlanGroupCode)
    const maskLength = hasMask ? removeCardNumberMask(getCardNumberMask(selectedPlanGroupCode)).length : 0
    const inputLength = cardNumber.length
    const minLength = inputLength === maskLength

    if(changeCardNumber && minLength) {
      try {
        const changedPlanGroupCode = Object.values(plansOptions).length > 0 ? plansOptions[0].planGroupCode : planGroupCode
        const fetchUrl = apiEndpoints.PERSON_PROFILE_BY_CARD(cardNumber, changedPlanGroupCode)
        const response = await axios.get(fetchUrl)

        const { status } = response

        if(status === 'OK') {
          setError(true)
          return true
        }
      } catch {
        setError(false)
        return false
      }
    }
  }

  return (
    <ModalGeneric
      hasButton={!readyToSave}
      btnText='APLICAR MODIFICAÇÕES'
      btnDisabled={ setBtnDisabled() || loading }
      btnLoading={loading}
      modalTitle={ success ? '' : 'Editar convênio' }
      customClassName='EditPlanModal'
      btnOnClick={handleSubmit}
    >
      {!success && (
        <Typography
          variant='content2'
          center
          inlineStyles={{ margin: '5px 0 30px' }}
        >
          Paciente {' '} <strong>{name}</strong>
        </Typography>
      )}
      {!readyToSave && !success && (
        <>
          <Dropdown
            dropDownOverflow
            dropDownItensToShow="3"
            options={ getPlanGroupOptions() }
            onChange={handleSelectPlanGroup}
            defaultValue={ getPlanGroupDefault() }
          />

          <Dropdown
            dropDownOverflow
            dropDownItensToShow="3"
            options={ plansOptions.length > 0 ? plansOptions : getPlansOptions() }
            onChange={handleSelectPlan}
            defaultValue={ getPlanDefault() }
          />

          {(!!activeCardNumber || planGroupHasCardNumber) && (
            <div className='EditPlanModal__card-number-wrapper'>
              <TextInput
                placeholder="Número da carteirinha*"
                inputHeight='4.8rem'
                onInputChange={ event => setCardNumber(removeCardNumberMask(event.target.value)) }
                value={cardNumber}
                mask={ getCardNumberMask(selectedPlanGroupCode) }
                onFocusOut={ () => checkCardNumber() }
                hasError={error}
                messageError='Carteirinha ja cadastrada'
              />
            </div>
          )}

          <Typography
            variant='content2'
            inlineStyles={{ margin: '30px 0 10px' }}
          >
            Caso você altere os planos acima, será preciso definir um motivo para a modificação
          </Typography>

          <Dropdown
            onSelect={handleSelectReason}
            options={reasons}
            placeholder='Selecione o motivo*'
            disabled={ setInputDisabled() }
          />

          <Textarea
            placeholder="Detalhes"
            value={details}
            onChange={ event => setDetails(event.target.value) }
            disabled={ setInputDisabled() }
          />

          <Typography
            variant='content2'
            italic
            inlineStyles={{ margin: '20px 0 0', width: '100%', color: '#898989' }}
          >
            *Preenchimento obrigatório
          </Typography>
        </>
      )}
      {readyToSave && !success && (
        <>
          <Typography
            variant='content2'
            color='danger'
            inlineStyles={{ marginBottom: '25px' }}
          >
            <strong>Atenção!{' '}</strong> Confira os detalhes e tenha certeza ao confirmar a modificação neste convênio.
          </Typography>

          <div className='EditPlanModal__wrapper-confirmation-data'>
            <table>
              <thead>
                <tr>
                  <th>Antes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Convênio:</td>
                  <td>{getPlanGroupName(activePlanId)}</td>
                </tr>
                <tr>
                  <td>Plano:</td>
                  <td>{getPlanName(activePlanId)}</td>
                </tr>
                {(!!activeCardNumber || planGroupHasCardNumber) && (
                  <tr>
                    <td>Carteirinha:</td>
                    <td>{(planGroupHasCardNumber && !!!activeCardNumber) ? 'Não possui' : activeCardNumber}</td>
                  </tr>
                )}
              </tbody>
            </table>

            <table>
              <thead>
                <tr>
                  <th>Depois</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Convênio:</td>
                  <td>{plan.groupName}</td>
                </tr>
                <tr>
                  <td>Plano:</td>
                  <td>{plan.name}</td>
                </tr>
                {(!!activeCardNumber || planGroupHasCardNumber) && (
                  <tr>
                    <td>Carteirinha:</td>
                    <td>{cardNumber}</td>
                  </tr>
                )}
              </tbody>
            </table>

            <Typography
              variant='content2'
              weight='bolder'
              inlineStyles={{ marginTop: '20px' }}
            >
              Motivo
            </Typography>

            <Typography
              variant='content2'
            >
              {reason.name}
            </Typography>

            {!!details && (
              <>
                <Typography
                  variant='content2'
                  weight='bolder'
                  inlineStyles={{ marginTop: '20px' }}
                >
                Detalhes
                </Typography>

                <Typography
                  variant='content2'
                >
                  {details}
                </Typography>
              </>
            )}
          </div>

          <div className='EditPlanModal__wrapper-buttons'>
            <Button
              variant='outlined'
              onClick={ () => dispatch(hideModal()) }
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              onClick={ () => handleSubmit() }
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Confirmar'}
            </Button>
          </div>
        </>
      )}
      {success && (
        <NotificationStep
          colWidth={12}
          btnText="Fechar"
          onSuccessClick={ () => {
            dispatch(hideModal())
            history.push(DASHBOARD_EDIT_USER(username, PATIENT))
          } }
          iconColor="#4ac326"
          iconName="Check"
          hasButton
          mainText={
            <Typography
              weight='bold'
              variant='header4'
              inlineStyles={{ margin: '5px 0 15px' }}
            >
              Edição de convênio realizada com sucesso!
            </Typography>
          }
        />
      )}
    </ModalGeneric>
  )
}

export default EditPlanModal
